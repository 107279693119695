<template>
  <div>
    <v-snackbar absolute top :timeout="5000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"> Deshabilitar MFA </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          :loading="loading"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h3 class="font-weight-bold">
                ¿Está seguro que desea deshabilitar el factor de doble
                autenticación ?
              </h3>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                rounded
                filled
                v-model="password"
                label="Ingrese su contraseña"
                type="password"
                :loading="loading"
                hide-details
              />
            </v-col>

            <!-- <v-col cols="12" sm="12" md="12">
              <div
                id="recaptcha-container"
                style="display: flex; justify-content: center; margin: 10px"
              />
            </v-col> -->

            <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
              <v-btn
                id="sign-in-button-disabled"
                height="50"
                rounded
                :loading="loading"
                block
                color="red darken-4"
                class="white--text"
                >Deshabilitar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="otpDialog = false"
        @success="otpConfirmed"
      />
    </v-dialog>
  </div>
</template>
      
      <script>
import { mapState } from "vuex";
import { fb, db } from "@/firebase";
import otp from "./insertCode.vue";

export default {
  name: "MFA-disable",
  //   props : ["authObject"],
  components: {
    otp,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: false,
      password: "",
      recaptchaVerifier: null,
      authObject: null,
      otpDialog: false,
      verificationId: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async next() {
      if (!this.password) {
        this.snackbarText = "Debe ingresar su contraseña de Ocho";
        this.snackbar = true;

        return;
      }

      try {
        let user = fb.auth().currentUser;

        let credential = fb.auth.EmailAuthProvider.credential(
          this.user.email,
          this.password
        );

        await user.reauthenticateWithCredential(credential);
      } catch (error) {
        console.log(error);
        switch (error.code) {
          case "auth/wrong-password":
            this.snackbarText = "La contraseña ingresada es incorrecta.";
            this.snackbar = true;
            break;

          case "auth/too-many-requests":
            this.snackbarText =
              "El acceso a esta cuenta se ha inhabilitado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede intentarlo más tarde.";
            this.snackbar = true;
            break;

          case "auth/multi-factor-auth-required":
            this.authObject = error.resolver;
            this.mfaLogin();
            break;

          default:
            this.snackbarText =
              "Ocurrió un error desconocido, inténtelo nuevamente.";
            this.snackbar = true;

            break;
        }

        this.loading = false;
      }
    },
    async otpConfirmed(otp) {
      try {
        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await this.authObject.resolveSignIn(multiFactorAssertion);

        var options = fb.auth().currentUser.multiFactor.enrolledFactors;

        await fb.auth().currentUser.multiFactor.unenroll(options[0]);

        this.$store.state.user.mfa = false;

        await db.collection("appUsers").doc(this.user[".key"]).update({
          mfa: false,
          mfaDisabledOn: new Date(),
        });

        this.$emit("success");
      } catch (error) {
        console.log(error);
        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }
      }
    },

    error(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      this.loading = false;
    },

    async mfaLogin() {
      this.loading = false;
      // this.recaptchaVerifier = new fb.auth.RecaptchaVerifier(
      //   "recaptcha-container"
      // );

      var phoneInfoOptions = {
        multiFactorHint: this.authObject.hints[0],
        session: this.authObject.session,
      };

      try {
        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      }
    },
  },

  mounted() {
    window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("sign-in-button-disabled", {
      size: "invisible",
      callback: async () => {
        this.next();
      },
    });

    recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  },
};
</script>
      
    